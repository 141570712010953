<template>
  <div>
    <el-form v-loading="loading" :model="dataForm" ref="dataForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="活动期数" prop="id" style="margin-bottom: 35px">
        <el-col :span="12">
          <el-input v-model="dataForm.id" readonly="readonly"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="活动名称" prop="name" style="margin-bottom: 35px">
        <el-col :span="12">
          <el-input v-model="dataForm.name" readonly="readonly"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="活动描述" prop="desc" style="margin-bottom: 35px">
        <el-col :span="12">
          <el-input v-model="dataForm.desc" :rows="2" type="textarea" readonly="readonly"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="投稿时间" prop="submit_time">
        <el-col :span="5">
          <el-form-item prop="submit_start">
            <el-date-picker type="datetime" placeholder="" v-model="dataForm.submit_start" style="width: 100%;" readonly="readonly"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5">
          <el-form-item prop="submit_end">
            <el-date-picker type="datetime" placeholder="" v-model="dataForm.submit_end" style="width: 100%;" readonly="readonly"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="投票时间"  prop="vote_time">
        <el-col :span="5">
          <el-form-item prop="vote_start">
            <el-date-picker type="datetime" placeholder="" v-model="dataForm.vote_start" style="width: 100%;" readonly="readonly"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5">
          <el-form-item prop="vote_end">
            <el-date-picker type="datetime" placeholder="" v-model="dataForm.vote_end" style="width: 100%;" readonly="readonly"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="公示时间"  prop="public_time">
        <el-col :span="5">
          <el-form-item prop="public_start">
            <el-date-picker type="datetime" placeholder="" v-model="dataForm.public_start" style="width: 100%;" readonly="readonly"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5">
          <el-form-item prop="public_end">
            <el-date-picker type="datetime" placeholder="" v-model="dataForm.public_end" style="width: 100%;" readonly="readonly"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>

      <el-form-item label="置顶图" prop="top_image" >
        <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            action="#"
            disabled>
          <img v-if="dataForm.top_image" :src="dataForm.top_image" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="投票设置" >
        <el-col :span="1">
          <span style="color: #606266">用户有效票数</span>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="vote_setting.count" >
            <el-input type="number" v-model="dataForm.vote_setting.count" placeholder="" style="width: 200px" readonly="readonly"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="投票投票刷新时间" prop="vote_setting.type" label-width="200" style="margin-left: 20px" >
            <el-select v-model="dataForm.vote_setting.type" style="width: 200px" disabled>
              <el-option :value="0" label="不刷新"></el-option>
              <el-option :value="1" label="每日刷新"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-form-item>

      <el-form-item label="奖项设置" prop="prize">
        <el-table
            :data="dataForm.prize"
            style="margin-top:20px"
            border
        >
          <el-table-column prop="name" label="奖项名称"> </el-table-column>
          <el-table-column prop="content" label="奖项内容"> </el-table-column>
        </el-table>
      </el-form-item>

      <el-form-item label="评审设置" prop="reviewer">
        <el-table
            :data="dataForm.reviewer"
            style="margin-top:20px"
            border
        >
          <el-table-column label="头像">
            <template slot-scope="scope">
              <img style="border-radius: 50px; width: 100px; height: 100px" :src="scope.row.avatar"/>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="昵称"> </el-table-column>
          <el-table-column prop="desc" label="简介"> </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="活动规则" prop="desc" style="height: fit-content">
        <div style="width: 375px; height: fit-content; max-height: 667px; overflow-x: hidden; overflow-y: auto">
          <quill-editor class="editor" v-model="dataForm.rule_desc" :options="editorOption" disabled>
          </quill-editor>
        </div>
      </el-form-item>
      <el-form-item style="margin-top: 50px; text-align: center">
        <el-button type="default" @click="close()" size="large">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
const toolbarOptions = [
  []                                         // remove formatting button
];

import { mapActions} from "vuex";
export default {
  name: "view_activity",
  components:{
  },
  async mounted() {
    this.loading = true;
    let _id = this.$route.query._id;
    let { data, errorCode } = await this.$http.activityDetail(_id);
    this.activity_data = data;
    this.loading = false;
    this.setFormData();
  },
  data() {
    return {
      dataForm: {
        id: '',
        name: '',
        desc: '',
        submit_start: '',
        submit_end: '',
        vote_start: '',
        vote_end: '',
        public_start: '',
        public_end: '',
        top_image: '',
        vote_setting: {
          count:-1,
          type: 0
        },
        rule_desc: '',
        rule_desc_url: '',
        prize: [],
        reviewer: []
      },
      editorOption:{
        placeholder:'请填写活动规则？',
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
            },
            modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
          },
          toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {

            }
          }
        }
      },
      loading: false
    };
  },
  methods: {
    setFormData() {
      if (this.activity_data && this.activity_data._id) {
        Object.keys(this.dataForm).forEach(key => {
          if(this.activity_data[key]) {
            this.dataForm[key] = this.activity_data[key];
          }
        });

        this.dataForm.submit_start = this.getDateTime(this.activity_data.submit_start || '');
        this.dataForm.submit_end = this.getDateTime(this.activity_data.submit_end || '');
        this.dataForm.vote_start = this.getDateTime(this.activity_data.vote_start || '');
        this.dataForm.vote_end = this.getDateTime(this.activity_data.vote_end || '');
        this.dataForm.public_start = this.getDateTime(this.activity_data.public_start || '');
        this.dataForm.public_end = this.getDateTime(this.activity_data.public_end || '');

        if(this.activity_data.vote_setting) {
          this.dataForm.vote_setting = {
            count:this.activity_data.vote_setting.count,
            type:this.activity_data.vote_setting.type,
          };
        }

        if(this.activity_data.prize) {
          this.dataForm.prize = this.activity_data.prize.map(r => {
            return {
              name: r.name,
              content: r.content,
              image: r.image
            };
          });
        }

        if(this.activity_data.reviewer) {
          this.dataForm.reviewer = this.activity_data.reviewer.map(r => {
            return {
              name: r.name,
              desc: r.desc,
              avatar: r.avatar
            };
          });
        }
      }
    },
    getDateTime(dataStr) {
      if(dataStr && dataStr.length) {
        return new Date(dataStr);
      }

      return '';
    },
    resetForm() {
      this.$refs.dataForm.resetFields();
    },
    close() {
      this.removeTabByPath("/activities/view_activity");
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ])
  }
}
</script>

<style lang="less" scoped>
.editor {
  line-height: normal !important;
  height: fit-content;
  width: 375px;
}
.prize_editor {
  line-height: normal !important;
  height: 200px;
  width: 100%;
  margin-bottom: 90px;
}
/deep/ .ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
/deep/ .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

/deep/ .ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

/deep/ .ql-editor .ql-size-10px { font-size: 10px; }
/deep/ .ql-editor .ql-size-12px { font-size: 12px; }
/deep/ .ql-editor .ql-size-14px { font-size: 14px; }
/deep/ .ql-editor .ql-size-16px { font-size: 16px; }
/deep/ .ql-editor .ql-size-18px { font-size: 18px; }
/deep/ .ql-editor .ql-size-20px { font-size: 20px; }
/deep/ .ql-editor .ql-size-24px { font-size: 24px; }
/deep/ .ql-editor .ql-size-26px { font-size: 26px; }
/deep/ .ql-editor .ql-size-32px { font-size: 32px; }
/deep/ .ql-editor .ql-size-48px { font-size: 48px; }

/deep/ .ql-editor .ql-size-20rpx { font-size: 10px; }
/deep/ .ql-editor .ql-size-24rpx { font-size: 12px; }
/deep/ .ql-editor .ql-size-26rpx { font-size: 13px; }
/deep/ .ql-editor .ql-size-32rpx { font-size: 16px; }
/deep/ .ql-editor .ql-size-48rpx { font-size: 24px; }

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: "32px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="48px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
  content: "48px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20rpx"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20rpx"]::before {
  content: "20rpx";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24rpx"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24rpx"]::before {
  content: "24rpx";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26rpx"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26rpx"]::before {
  content: "26rpx";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32rpx"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32rpx"]::before {
  content: "32rpx";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="48rpx"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48rpx"]::before {
  content: "48rpx";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

/deep/ .ql-snow .ql-picker.ql-font .ql-picker-label::before,
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

</style>
